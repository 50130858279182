<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">{{ $t("SUBSCRIPTIONS.EDIT_SUBSCRIPTION") }}</h3>
            </div>
            <div class="col-4 text-right">
              <base-button
                @click="goBack"
                type="button"
                class="btn btn-sm btn-primary"
              >
                {{ $t("COMMON.RETURN_TO_LIST") }}
              </base-button>
            </div>
          </div>
          <div class="card-body">
            <subscription-form
              :loading="loading"
              :subscriptionData="subscription"
              :formErrors="formErrors"
              @subscriptionSubmitted="handleSubmit"
              @formChanged="() => (alertLeave = true)"
            />
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import SubscriptionForm from "./partials/SubscriptionForm.vue";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultSubscription from "./defaultSubscription";

export default {
  layout: "DashboardLayout",

  components: { SubscriptionForm },

  mixins: [alertLeave],

  data() {
    return {
      subscription: cloneDeep(defaultSubscription),
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("subscriptions/get", id);
        this.subscription = this.$store.getters["subscriptions/subscription"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    goBack() {
      this.$router.push({ name: "List Subscriptions" });
    },

    viewSubscription() {
      this.alertLeave = false;
      this.$router.push({
        name: "View Subscription",
        params: { id: this.subscription.id },
      });
    },

    async handleSubmit(subscription) {
      this.loading = true;
      const subscriptionData = cloneDeep(subscription);

      try {
        await this.$store.dispatch("subscriptions/update", subscriptionData);
        this.$notify({
          type: "success",
          message: this.$t("SUBSCRIPTIONS.SUBSCRIPTION_UPDATED"),
        });
        this.viewSubscription();
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
